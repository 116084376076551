import {Card} from "@mantine/core";
import PageTemplate from "../pageTemplate";

export default function About() {
    return (
        <PageTemplate>
            <Card m={64}>
                <p>
                    Introducing Das Fad: Your Creative Hub for Digital Architecture and Collaboration
                </p>
                <p>
                    Das Fad is a groundbreaking platform designed to enable collaboration between software architects,
                    security professionals, software development teams, and risk management teams.
                </p>
                <p>
                    Combining cutting-edge technology with a user-friendly interface,
                    Das Fad empowers professionals to push the boundaries of creativity
                    while ensuring robust security and seamless collaboration.
                </p>
                <p>
                    With our intuitive tools, you can quickly build and share designs for new
                    software platforms and instantly get feedback from different
                    stakeholders. Whether you are working on a simple layout or an intricate global network,
                    you can quickly vet, change, and share your system designs with your team.
                </p>
                <p>
                    Our solution is secure by design, ensuring that your
                    intellectual property and sensitive data are protected at all times.
                    Das Fad employs advanced encryption and secure access protocols
                    to ensure that your information remains confidential and secure.
                </p>
                <p>
                    Dive deep into your projects with our forensic analysis tools.
                    Identify potential design flaws, assess structural integrity,
                    and integrate with your incident tracking tools to quickly
                    assess and mitigate risks.
                </p>
                <p>
                    Our model is collaboration first and all aspects are designed to be used
                    with multiple users while retaining all audit history of changes.

                </p>
                <p>
                    Designed by software professionals for software professionals,
                    the experience is tailored to your needs.

                </p>
            </Card>
        </PageTemplate>
    )
}